import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table} from "react-bootstrap";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import PropagateLoader from "react-spinners/RingLoader";

import { getOneUser, getAllUsers } from "../actions/user.action";
import Capitalize from "../common/Capitalize";
import EditAccountModal from "../Modals/EditAccountModal";

const CourseAccounts = (props) => {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false)
  const [editId, setEditId] = useState("");
  const [spinner, setSpinner] = useState(true);

  const account = useSelector(state => state.allUsers.account);

  useEffect(() => {
    dispatch(getAllUsers(props?.user?.accessToken));
  }, [showEdit]);

  useEffect(() => {
    if (props?.users) {
      setSpinner(false);
    }
   }, [props?.users]);
  


  const handlerEditClick = (id) => {
    setShowEdit(true);
    setEditId(id)
    dispatch(getOneUser(id, props?.user?.accessToken));
  }

  const handlerDeleteClick = () => {

  }

  const override: CSSProperties = {
    display: "block",
    margin: "20px auto",
    borderColor: "red",
  };


  return (
    <div className="card shadow p-3 mt-1 boarduser" style={{margin: "0 auto" }}>
      <div className=''>
        <div className='mb-3'>
          <h5 className="fw-light text-capitalize">
            {/* <strong>Course :</strong> {user.coursename.split("-").join(" ")} */}
          </h5>
          <h4 className="text-center bg-primary text-white mt-4 pt-3 pb-3">
            <span>Course Accounts List</span>
          </h4>
        </div>
        <PropagateLoader color={'#04a0c8'} cssOverride={override} loading={spinner}/>
      </div>
      {props?.users ? (
        <div className='mt-3'>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Course</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {props?.users?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{Capitalize(item.coursename)}</td>
                    <td>{item.email}</td>
                    <td>
                      <div>
                        <PencilFill className="mx-3 action-icon" size={25} color="#55a630" onClick={() => handlerEditClick(item._id)} />
                        <TrashFill className="action-icon" size={25} color="#e71d36" onClick={handlerDeleteClick} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <EditAccountModal show={showEdit} setShowEdit={setShowEdit} accountId={editId} courseName={account.coursename} email={account.email} accessToken={props.user.accessToken} />
        </div>
      ) : (
        <div className="mt-3 mb-3">
          <h3>Empty List...</h3>
        </div>

      )

      }
    </div>
  );
};

export default CourseAccounts;

