import axios from "axios";
import { API_url } from '../config/config'

const API_URL = `${API_url}/api/auth/`;
const API_URL1 = `${API_url}/api/`;

const register = (coursename, email, password) => {
  return axios.post(API_URL + "signup", {
    coursename,
    email,
    password,
  });;
}

const addAdminCourse = (coursename, email, password, accessToken) => {
  
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.post(API_URL1 + "add-admin-course", {coursename,email,password},config);
};



const login = (email, password) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {

  const user = JSON.parse(localStorage.getItem("user"));

  const config = {
    headers: {
      'x-access-token': user.accessToken,
    },
  };

  localStorage.removeItem("user");
  
  return axios.post(API_URL1 + "auth/srvout", {userId : user.id, accessToken : user.accessToken},config);

  
};

export default {
  register,
  addAdminCourse,
  login,
  logout,
};
