import {
 FILE_UPLOAD_SUCCESS,
 FILE_UPLOAD_FAIL
  } from "../actions/types";
  
  
  const initialState ={};
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case FILE_UPLOAD_SUCCESS:
        return {
          ...state,
          filename : payload,
          isUploded:true
        };
      case FILE_UPLOAD_FAIL:
        return {
          ...state,
          isUploded:false
         };
     default:
        return state;
    }
  }
  