import {
GET_MODSTUDENTS_SUCCESS,
GET_MODSTUDENTS_FAIL,
GET_STUDENTS_SUCCESS,
GET_STUDENTS_FAIL,
  } from "../actions/types";
  
  
  const initialState ={modStudents:{},allStudents:{}};
  
  export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case GET_MODSTUDENTS_SUCCESS:
        return {
          ...state,
          modStudents:{...state.modStudents, payload},
         };
      case GET_MODSTUDENTS_FAIL:
        return {
          ...state,
          };
          case GET_STUDENTS_SUCCESS:
            return {
              ...state,
              allStudents:{...state.allStudents, payload},
             };
          case GET_STUDENTS_FAIL:
            return {
              ...state,
              };
     default:
        return {
          ...state,
          };
    }
  }
  

  //students: [...state.students, payload],