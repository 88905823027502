import React, { useState,  useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import EditAccountComp from "../components/EditAccountComp";

function EditAccountModal(props) {

 return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShowEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Course Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <EditAccountComp {...props}/>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default EditAccountModal;

