import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import { PencilFill, TrashFill, Search } from "react-bootstrap-icons";

import { getStudents } from "../actions/student.action";
import { getCoursess } from "../actions/course.action";
import Capitalize from "../common/Capitalize";

function AllCourseForAdmin() {
  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);
  const courses = useSelector(state => state.courses.payload);
  const user = useSelector((state) => state.auth.user);
  const allStudents = useSelector((state) => state.modStudents.allStudents);

  const [successful, setSuccessful] = useState(false);
  const [coursename, setCourseName] = useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [searchStudent, setSearchStudent] = useState("");

  useEffect(() => {
    dispatch(getCoursess(user.accessToken));
  }, []);

  useEffect(() => {
    if (coursename) {
      dispatch(getStudents(coursename, user.accessToken));
    }
  }, [dispatch, coursename]);


  const handleClose = () => setShow(false);

  const onChangeCourseName = (e) => {
    const coursename = e.target.value;
    setCourseName(coursename.toLowerCase());
  };

  const handlerEditClick = () => {

  }

  const handlerOpenModal = (id) => {
    setId(id);
    setShow(true);
  }

  const handleFindStudent = (e) => {
    setSearchStudent(e.target.value);
  }

  const handlerDeleteClick = () => {
    // dispatch(deleteModStudent(id, user.accessToken))
    //   .then(() => {
    //     setSuccessful(true);
    //   })
    //   .catch(() => {
    //     setSuccessful(false);
    //   });
    //   setTimeout(() => {
    //     setShow(false); 
    //     dispatch(getModStudents("", user.accessToken)); 
    //   }, 2000);

  }

   // Filter Student
   const filterStudents = allStudents?.payload?.students?.filter((student) => {
    return ((student.slackname.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1) || (student.email.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1))
  })

  return (
    <div className="card shadow p-3 mt-3 boarduser" style={{ maxWidth: "1024px", margin: "0 auto" }}>
      <div className=''>
        <div className=''>
          {/* <h5 className="fw-light text-capitalize">
            <strong></strong>
          </h5> */}
          <h4 className="text-center bg-primary mt-4 pt-3 pb-3">
            <span>Student List</span>
          </h4>
        </div>

        <div className="form-groupmt mt-4">
          <Row>
            <Col className="mb-3" md={6}>
              <Form.Select name="coursename" onChange={onChangeCourseName}>
                <option className="fw-light">Select Course Name</option>
                {courses?.map((item, index) => {
                  return (
                    <option key={index} value={item.name} className="text-capitalize">
                      {Capitalize(item.name.split("-").join(" "))}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col md={6}>
              <InputGroup>
                <InputGroup.Text id="basic-addon1"><Search /></InputGroup.Text>
                <InputGroup.Text>Email or Slack Name</InputGroup.Text>
                <Form.Control
                  aria-label="searchstudent"
                  name="searchstudent"
                  value={searchStudent}
                  onChange={handleFindStudent}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>

      </div>
      {allStudents?.payload?.students ? (
        <div className='mt-2'>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Full Name</th>
                <th>Slack Name</th>
                <th>Email</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {filterStudents?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{Capitalize(item.fullname)}</td>
                    <td>{item.slackname}</td>
                    <td>{item.email}</td>
                    <td>
                      <div style={{ "textAlign": "center" }}>
                        <PencilFill className="mx-3 action-icon" size={25} color="#55a630" title="Edit" onClick={() => handlerEditClick(item._id)} />
                        <TrashFill className="action-icon" size={25} color="#e71d36" title="Delete" onClick={() => handlerOpenModal(item._id)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="mt-3 mb-3">
          <h3>Empty List...</h3>
        </div>

      )

      }
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <p>When you delete the student, all the attendances belonging to it will also be deleted.</p>
            <p>You will not see this student in your course.</p>
          </div>
          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handlerDeleteClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AllCourseForAdmin
