import axios from "axios";
import { API_url } from '../config/config';


const API_URL = `${API_url}/api/`;


const getClasses= async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-class",config);

};

const getClassesSa= async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-classsa",config);

};


const createClassName = async (name,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.post(API_URL + "create-class",{name},config);

};

const updateClassNameStatus = async (id, accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.put(API_URL + `update-class/${id}`,"",config);

};


  export default {
    getClasses,
    getClassesSa,
    createClassName,
    updateClassNameStatus
  };
  