import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_UNREADNOTIFICATION_SUCCESS,
  GET_UNREADNOTIFICATION_FAIL,
  SEND_NOTIFICATIONEMAIL_SUCCESS,
  SEND_NOTIFICATIONEMAIL_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  SET_MESSAGE,
  } from "./types";
  
  import ClassService from "../services/notification.service";

  export const getNotifications = (accessToken) => (dispatch) => {
    return ClassService.getNotifications(accessToken).then(
      (response) => {
         dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          noti: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_NOTIFICATION_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getUnReadNotifications = (accessToken) => (dispatch) => {
    return ClassService.getUnReadNotifications(accessToken).then(
      (response) => {
         dispatch({
          type: GET_UNREADNOTIFICATION_SUCCESS,
          unnoti: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_UNREADNOTIFICATION_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const sendEmail = (id, accessToken) => (dispatch) => {
    return ClassService.sendEmail(id, accessToken).then(
      (response) => {
         dispatch({
          type: SEND_NOTIFICATIONEMAIL_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SEND_NOTIFICATIONEMAIL_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };



  export const deleteNotification = (id, accessToken) => (dispatch) => {
    return ClassService.deleteNotification(id, accessToken).then(
      (response) => {
         dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: DELETE_NOTIFICATION_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  