import {
 CREATE_STUDENT_SUCCESS,
 CREATE_STUDENT_FAIL,
 GET_STUDENT_SUCCESS,
 GET_STUDENT_FAIL
  } from "../actions/types";
  
  
  const initialState ={student:{}};
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_STUDENT_SUCCESS:
        return {
          ...state,
          isStudent:true,
        };
      case CREATE_STUDENT_FAIL:
        return {
          ...state,
          isStudent:false,
         };
         case GET_STUDENT_SUCCESS:
        return {
          ...state,
          student:payload,
        };
      case GET_STUDENT_FAIL:
        return {
          ...state,
       };
     default:
        return {
          ...state,
          isStudent:null,
        }
    }
  }
  