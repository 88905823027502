import {
 CREATE_ADMINCOURSE_SUCCESS,
 CREATE_ADMINCOURSE_FAIL,
 GET_ADM_COURSE_SUCCESS,
 GET_ADM_COURSE_FAIL
  } from "../actions/types";
  
  
  const initialState ={};
  
export default function (state = initialState, action) {
    const { type, payload } = action;
   switch (type) {
      case CREATE_ADMINCOURSE_SUCCESS:
        return {
          ...state,
         };
      case CREATE_ADMINCOURSE_FAIL:
        return {
          ...state,
         };
         case GET_ADM_COURSE_SUCCESS:
          return {
            ...state,
            payload
          };
        case GET_ADM_COURSE_FAIL:
          return {
            ...state,
           };
     default:
        return {
          ...state,
         }
    }
  }
  