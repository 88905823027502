import {
 CREATE_ATTENDANCE_SUCCESS,
 CREATE_ATTENDANCE_FAIL
  } from "../actions/types";
  
  
  const initialState ={};
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ATTENDANCE_SUCCESS:
        return {
          ...state,
          payload
        };
      case CREATE_ATTENDANCE_FAIL:
        return {
          ...state,
        };
     default:
        return {
          ...state,
       }
    }
  }

     
  