import React, {useState} from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useSelector} from "react-redux";

import AddSingleStudent from "../components/AddSingleStudent";
import AddMultipleStudent from "../components/AddMultipleStudent";


const BoardModerator = (props) => {

  const state = useSelector((state) => state);

  const [isStudent, setIsStudent] = useState(false);
  const [isStudents, setIsStudents] = useState(false);


  return (
    <div
    className="card shadow p-3 mt-3 boarduser"
    style={{
      maxWidth: "1024px",
      margin: "0 auto",
    }}
  >
    <div>
      <Tabs defaultActiveKey="addstudent" id="mod-panel" className=" mb-3">
        <Tab eventKey="addstudent" title="Add Single Student">
          <AddSingleStudent {...state} isStudent={isStudent} setIsStudent={setIsStudent} setIsStudents={setIsStudents}/>
        </Tab>
         <Tab eventKey="allcontract" title="Add Multiple Students">
          <AddMultipleStudent {...state} isStudents={isStudents} setIsStudents={setIsStudents} setIsStudent={setIsStudent}/>
        </Tab>
      </Tabs>
    </div>
  </div>
  );
};

export default BoardModerator;