import {
  UPDATE_ADMINPASS_SUCCESS,
  UPDATE_ADMINPASS_FAIL,
  GET_ALLUSER_SUCCESS,
  GET_ALLUSER_FAIL,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  UPDATE_ACCOUNTPAS_SUCCESS,
  UPDATE_ACCOUNTPAS_FAIL,
  SET_MESSAGE,
  } from "./types";
  
  import UserService from "../services/user.service";

  export const updateAdminPassword = (id,password, newPassword, accessToken) => (dispatch) => {
    return UserService.updateAdminPassword(id,password, newPassword, accessToken).then(
      (response) => {
         dispatch({
          type: UPDATE_ADMINPASS_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_ADMINPASS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getAllUsers = (accessToken) => (dispatch) => {
    return UserService.getAllUsers(accessToken).then(
      (response) => {
         dispatch({
          type: GET_ALLUSER_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_ALLUSER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getOneUser = (id,accessToken) => (dispatch) => {
    return UserService.getOneUser(id,accessToken).then(
      (response) => {
         dispatch({
          type: GET_USER_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_USER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const updateAccount = (accountId,coursename, email,accessToken) => (dispatch) => {
    return UserService.updateAccount(accountId,coursename, email,accessToken).then(
      (response) => {
         dispatch({
          type: UPDATE_ACCOUNT_SUCCESS,
         });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_ACCOUNT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const updateAccountPassword = (accountId,newPassword,accessToken) => (dispatch) => {
    return UserService.updateAccountPassword(accountId,newPassword,accessToken).then(
      (response) => {
         dispatch({
          type:UPDATE_ACCOUNTPAS_SUCCESS,
         });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_ACCOUNTPAS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };





  