import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_UNREADNOTIFICATION_SUCCESS,
  GET_UNREADNOTIFICATION_FAIL
} from "../actions/types";


const initialState = {
  allNotification: [],
  unReadCount:0
};

export default function (state = initialState, action) {
  const { type, payload, noti, unnoti } = action;
  switch (type) {
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        allNotification: [...state.allNotification, noti]
      };
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
      };
    case GET_UNREADNOTIFICATION_SUCCESS:
      return {
        ...state,
        unReadCount:unnoti
      };
    case GET_UNREADNOTIFICATION_FAIL:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      }
  }
}

