import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Table, Form } from "react-bootstrap";

import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { getClassesSa, createClassName, updateClassNameStatus } from "../actions/class.action";

import Capitalize from "../common/Capitalize";

const required = (value) => {
  if (value === undefined || !value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const AddClassName = (props) => {
  const form = useRef();
  const checkBtn = useRef();


  const [successful, setSuccessful] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [className, setClassName] = useState("")

  const { accessToken } = useSelector((state) => state.auth.user);
  const { message } = useSelector((state) => state.message);
  const classes = useSelector((state) => state.classes.payload);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClassesSa(accessToken));
  }, []);

  useEffect(() => {
    if (successful)
      dispatch(getClassesSa(accessToken));
  }, [successful]);


  const onChangeCourseName = (e) => {
    setClassName(e.target.value);
  };

  const handleChangeCourseNameStatus = (id) => {
    setSuccessful(false);
    dispatch(updateClassNameStatus(id, accessToken))
      .then(() => {
        setSuccessful(true);
       })
      .catch(() => {
        setSuccessful(false);
      });
  }


  const handleFormSubmit = (e) => {
    setSpinner(true);
    setSuccessful(false);
    e.preventDefault();
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        createClassName(className, accessToken)
      )
        .then(() => {
          setSuccessful(true);
          setSpinner(false);      
        })
        .catch(() => {
          setSuccessful(false);
          setSpinner(false);  
        });
    }
  };

  return (
    <div
      className="card shadow p-3 mt-3 boarduser"
      style={{ maxWidth: "1024px", margin: "0 auto" }}
    >
      <Col xs={12} sm={12} md={6} style={{ margin: "0 auto" }}>
        <Form1 onSubmit={handleFormSubmit} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="classsename">Class Name</label>
              <Input
                type="text"
                className="form-control"
                name="classsename"
                value={className}
                onChange={onChangeCourseName}
                validations={[required]}
              />
            </div>

            <div className="form-group d-grid gap-2 my-3">
              <button className="btn btn-primary">Save</button>
            </div>
          </div>

          {spinner && (
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-info" role="status">
                {/* <span class="visually-hidden">Loading...</span> */}
              </div>
            </div>
          )}



          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form1>
      </Col>

      <div className="fw-light">
        <label>Sample Class Name : "admin-class"</label>
      </div>

      {/* Code List */}

      {classes && (
        <div className="mt-3">
          {/* <div className='mb-3 mt-3'>
				<h5>
			    List Codes.....
				</h5>
			</div> */}

          <Table striped bordered hover responsive >
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Class Name</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {classes.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={!item.isActive ? "tr-pasive" : "table-active"}
                  >
                    <td>{index + 1}</td>
                    <td>{Capitalize(item.name.split("-").join(" "))}</td>

                    <td style={{ width: "30px", alignItems: "center" }}>
                      <Form.Check
                        inline
                        name="status"
                        type="switch"
                        title="Chenge class neme status"
                        checked={item.isActive}
                        onChange={() => handleChangeCourseNameStatus(item._id)}
                      />
                    </td>
                  </tr>
                );

              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AddClassName;
