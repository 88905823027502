export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";
export const CREATE_STUDENT_FAIL = "CREATE_STUDENT_FAIL ";

export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";

export const CREATE_STUDENTS_SUCCESS = "CREATE_STUDENTS_SUCCESS";
export const CREATE_STUDENTS_FAIL = "CREATE_STUDENTS_FAIL ";

export const CREATE_ACCESSCODE_SUCCESS = "CREATE_ACCESSCODE_SUCCESS";
export const CREATE_ACCESSCODE_FAIL = "CREATE_ACCESSCODE_FAIL ";

export const GET_ACCESSCODE_SUCCESS = "GET_ACCESSCODE_SUCCESS";
export const GET_ACCESSCODE_FAIL = "GET_ACCESSCODE_FAIL ";

export const DELETE_ACCESSCODE_SUCCESS = "DELETE_ACCESSCODE_SUCCESS";
export const DELETE_ACCESSCODE_FAIL = "DELETE_ACCESSCODE_FAIL ";

export const UPDATE_ACCESSCODE_SUCCESS = "UPDATE_ACCESSCODE_SUCCESS";
export const UPDATE_ACCESSCODE_FAIL = "UPDATE_ACCESSCODE_FAIL ";

export const GET_MODSTUDENTS_SUCCESS = "GET_MODSTUDENTS_SUCCESS";
export const GET_MODSTUDENTS_FAIL = "GET_MODSTUDENTS_FAIL ";

export const CREATE_ATTENDANCE_SUCCESS = "CREATE_ATTENDANCE_SUCCESS";
export const CREATE_ATTENDANCE_FAIL = "CREATE_ATTENDANCE_FAIL ";

export const GET_MOD_ATTENDANCES_SUCCESS = "GET_MOD_ATTENDANCES_SUCCESS";
export const GET_MOD_ATTENDANCES_FAIL = "GET_MOD_ATTENDANCES_FAIL ";

export const GET_MOD_ATTENDANCES_CLASS_SUCCESS = "GET_MOD_ATTENDANCESCLASS_SUCCESS";
export const GET_MOD_ATTENDANCES_CLASS_FAIL = "GET_MOD_ATTENDANCES_CLASS_FAIL ";

export const GET_MOD_CLASS_SUCCESS = "GET_MOD_CLASS_SUCCESS";
export const GET_MOD_CLASS_FAIL = "GET_MOD_CLASS_FAIL ";

export const GET_MOD_COURSE_SUCCESS = "GET_MOD_COURSE_SUCCESS";
export const GET_MOD_COURSE_FAIL = "GET_MOD_COURSE_FAIL ";

export const GET_ADM_COURSE_SUCCESS = "GET_ADM_COURSE_SUCCESS";
export const GET_ADM_COURSE_FAIL = "GET_ADM_COURSE_FAIL ";

export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAIL = "FILE_DOWNLOAD_FAIL ";

export const CREATE_ADMINCOURSE_SUCCESS = "CREATE_ADMINCOURSE_SUCCESS";
export const CREATE_ADMINCOURSE_FAIL = "CREATE_ADMINCOURSE_FAIL ";

export const CREATE_COURSENAME_SUCCESS = "CREATE_COURSENAME_SUCCESS";
export const CREATE_COURSENAME_FAIL = "CREATE_COURSENAME_FAIL ";

export const CREATE_CLASSNAME_SUCCESS = "CREATE_CLASSNAME_SUCCESS";
export const CREATE_CLASSNAME_FAIL = "CREATE_CLASSNAME_FAIL ";

export const UPDATE_COURSENAME_SUCCESS = "UPDATE_COURSENAME_SUCCESS";
export const UPDATE_COURSENAME_FAIL = "UPDATE_COURSENAME_FAIL ";

export const UPDATE_CLASSNAME_SUCCESS = "UPDATE_CLASSNAME_SUCCESS";
export const UPDATE_CLASSNAME_FAIL = "UPDATE_CLASSNAME_FAIL ";

export const UPDATE_ADMINPASS_SUCCESS = "UPDATE_ADMINPASS_SUCCESS";
export const UPDATE_ADMINPASS_FAIL = "UPDATE_ADMINPASS_FAIL ";

export const GET_ALLUSER_SUCCESS = "GET_ALLUSER_SUCCESS";
export const GET_ALLUSER_FAIL = "GET_ALLUSER_FAIL ";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL ";

export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL ";

export const UPDATE_ACCOUNTPAS_SUCCESS = "UPDATE_ACCOUNTPAS_SUCCESS";
export const UPDATE_ACCOUNTPAS_FAIL = "UPDATE_ACCOUNTPAS_FAIL ";

export const DELETE_MODSTUDENT_SUCCESS = "DELETE_MODSTUDENT_SUCCESS";
export const DELETE_MODSTUDENT_FAIL = "DELETE_MODSTUDENT_FAIL ";

export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL ";

export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAIL = "GET_STUDENT_FAIL ";

export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL";

export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL ";

export const GET_UNREADNOTIFICATION_SUCCESS = "GET_UNREADNOTIFICATION_SUCCESS";
export const GET_UNREADNOTIFICATION_FAIL = "GET_UNREADNOTIFICATION_FAIL ";

export const SEND_NOTIFICATIONEMAIL_SUCCESS = "SEND_NOTIFICATIONEMAIL_SUCCESS";
export const SEND_NOTIFICATIONEMAIL_FAIL = "SEND_NOTIFICATIONEMAIL_FAIL ";

export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL ";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
