import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table, Form, Modal, Button } from "react-bootstrap";
import { Clipboard2CheckFill, KeyFill, TrashFill } from "react-bootstrap-icons";

import { getUnReadNotifications } from "../actions/notification.action";

import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import {
  createAccessCode,
  getAccessCode,
  updateAccessCode,
  deleteAccessCode
} from "../actions/accessCode.action";
import { getClasses } from "../actions/class.action";
import { clearMessage } from "../actions/message";

const required = (value) => {
  if (value === undefined || !value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const GenerateCode = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const codeAreaRef = React.createRef();

  const [successful, setSuccessful] = useState(false);
  const [lessonDate, setLessonDate] = useState();
  const [zoomlink, setZoomLink] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("")

  const { accessToken } = useSelector((state) => state.auth.user);
  const { message } = useSelector((state) => state.message);
  const classes = useSelector((state) => state.classes.payload);
  const accessCodes = useSelector((state) => state.accessCodes.payload);

  // const myTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dispatch = useDispatch();

 useEffect(() => {
    dispatch(getClasses(accessToken));
    // dispatch(getAccessCode(accessToken));
  }, []);

  useEffect(() => {
    dispatch(getAccessCode(accessToken));
  }, [dispatch,successful]);

  const onChangeDate = (e) => {
    setLessonDate(e.target.value);
  };

  const onChangeZoomlink = (e) => {
    setZoomLink(e.target.value);
  };

  const selectOptionHandler = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleFormSubmit = (e) => {
    setSuccessful(false);
    e.preventDefault();
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        createAccessCode(lessonDate, zoomlink, selectedClass, accessToken)
      )
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const handleChangeCodeStatus = (id) => {
    setSuccessful(false);
    dispatch(updateAccessCode(id, accessToken))
      .then(() => {
        setSuccessful(true);
        dispatch(getUnReadNotifications(accessToken));
      })
      .catch(() => {
        setSuccessful(false);
      });
   };

  const handleClose = () => setShow(false);

  const handlerOpenModal = (id) => {
    setDeleteSuccess(false);
    setId(id);
    setShow(true);
  }

  const handlerDeleteClick = () => {
    dispatch(deleteAccessCode(id, accessToken))
      .then(() => {
        setDeleteSuccess(true);
      })
      .catch(() => {
        setDeleteSuccess(false);
      });
    setTimeout(() => {
      setShow(false);
      dispatch(getAccessCode(accessToken));
    }, 1000);

  }

  function copyToClipboard(e) {
    codeAreaRef.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 1500);
  }

  return (
    <div
      className="card shadow p-3 mt-3 boarduser"
      style={{ maxWidth: "1024px", margin: "0 auto" }}
    >
      <Col lg={6} style={{ margin: "0 auto" }}>
        <Form1 onSubmit={handleFormSubmit} ref={form}>
          <div>
            <div className="form-group">
              <Row>
                <Col md={6} xs={12}>
                  <label htmlFor="lessondate">Lesson Date</label>
                  <Input
                    type="date"
                    className="form-control"
                    name="lessondate"
                    value={lessonDate}
                    onChange={onChangeDate}
                    validations={[required]}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <label htmlFor="class">Select Class</label>
                  <Form.Select name="class" onChange={selectOptionHandler}>
                    <option className="fw-light"></option>
                    {classes?.map((item, index) => {
                      return (
                        <option key={index} value={item.name} className="text-capitalize">
                          {item.name.split("-").join(" ")}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <label htmlFor="zoomlink">Zoom Link</label>
              <Input
                type="text"
                className="form-control"
                name="zoomlink"
                value={zoomlink}
                onChange={onChangeZoomlink}
                validations={[required]}
              />
            </div>

            <div className="form-group d-grid gap-2 my-3">
              <button className="btn btn-primary">Generate Code</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form1>
      </Col>

      <div className="fw-light text-capitalize">
        {/* <label>{accessCodes?.classroom.split("-").join(" ")}</label> */}
      </div>

      {/* Code List */}

      {accessCodes && (
        <div className="mt-3">
          {/* <div className='mb-3 mt-3'>
				<h5>
			    List Codes.....
				</h5>
			</div> */}

          <Table striped bordered hover responsive >
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Lesson Date</th>
                <th>Lesson</th>
                <th>Code</th>
                <th>Active</th>
                <th>Del</th>
              </tr>
            </thead>
            <tbody>
              {accessCodes.map((item, index) => {
                var date = new Date(item.lessondate).toLocaleDateString();
                return (
                  <tr
                    key={index}
                    className={!item.isActive ? "tr-pasive" : "table-active"}
                  >
                    <td>{index + 1}</td>
                    <td>{date}</td>
                    <td>{item.class.split("-").join(" ")}</td>
                    <td
                    >

                      {item.isActive &&
                        <>
                          <span className="d-flex align-items-baseline" style={{ "color": "green" }}>
                            <KeyFill color="" className="pt-2" size={25} />
                            {"  "}
                            <textarea
                              ref={codeAreaRef}
                              value={item.code}
                              cols="5"
                              rows="1"
                              style={{
                                fontWeight: "bold",
                                outline: "none",
                                border: "none",
                                resize: "none",
                              }}
                              readOnly
                            />
                            <Clipboard2CheckFill
                              className="pt-1 mx-1"
                              onClick={copyToClipboard}
                              size={26}
                              color=""
                              title="Copy to clipboard!"
                              style={{ cursor: "pointer" }}
                            />
                            {copySuccess}
                          </span>

                        </>

                      }
                    </td>
                    <td style={{ width: "30px", alignItems: "center" }}>
                      <Form.Check
                        inline
                        name="showCode"
                        type="switch"
                        title="Change code state"
                        checked={item.isActive}
                        onChange ={() => handleChangeCodeStatus(item._id)}
                      />
                    </td>
                    <td>
                      <div style={{ "textAlign": "center" }}>
                        {item.isActive &&
                          <TrashFill className="action-icon" size={25} color="#e71d36" title="Delete" onClick={() => handlerOpenModal(item._id)} />
                        }
                      </div>
                    </td>
                  </tr>
                );

              })}
            </tbody>
          </Table>
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Accecc Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <p>When you delete this code, you cannot see this date in your attendance.</p>
            <p>Are you sure you want to delete?</p>
          </div>
          {(message && deleteSuccess) && (
            <div className="form-group">
              <div
                className={
                  deleteSuccess ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </Modal.Body>
        {!deleteSuccess && (
              <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handlerDeleteClick}>
                Delete
              </Button>
            </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default GenerateCode;

