import {
    CREATE_STUDENTS_SUCCESS,
    CREATE_STUDENTS_FAIL,
    CREATE_STUDENT_SUCCESS,
    CREATE_STUDENT_FAIL,
    FILE_UPLOAD_SUCCESS,
    GET_MODSTUDENTS_SUCCESS,
    GET_MODSTUDENTS_FAIL,
    FILE_UPLOAD_FAIL,
    FILE_DOWNLOAD_SUCCESS,
    FILE_DOWNLOAD_FAIL,
    DELETE_MODSTUDENT_SUCCESS,
    DELETE_MODSTUDENT_FAIL,
    GET_STUDENTS_SUCCESS,
    GET_STUDENTS_FAIL,
    GET_STUDENT_SUCCESS,
    GET_STUDENT_FAIL,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAIL,
    SET_MESSAGE,
  } from "./types";
  
  import StudentService from "../services/student.service";

 export const createStudent = (fullname, slackname, email,accessToken) => (dispatch) => {
    return StudentService.createStudent(fullname, slackname, email, accessToken).then(
      (response) => {
         dispatch({
          type: CREATE_STUDENT_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_STUDENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const fileUpload = (data, accessToken) => (dispatch) => {
    return StudentService.fileUpload(data, accessToken).then(
      (response) => {
         dispatch({
          type: FILE_UPLOAD_SUCCESS,
          payload:data.name 
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: FILE_UPLOAD_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const createStudents = (filename, accessToken) => (dispatch) => {
    return StudentService.createStudents(filename, accessToken).then(
      (response) => {
          dispatch({
          type: CREATE_STUDENTS_SUCCESS,
          payload: response.data
         });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_STUDENTS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  

  export const getModStudents = (classname, accessToken) => (dispatch) => {
    return StudentService.getModStudents(classname, accessToken).then(
      (response) => {
            dispatch({
          type: GET_MODSTUDENTS_SUCCESS,
          payload:response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MODSTUDENTS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  

  export const fileDownload = (accessToken) => (dispatch) => {
    return StudentService.fileDownloadServices(accessToken).then(
      (response) => {
            dispatch({
          type: FILE_DOWNLOAD_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: FILE_DOWNLOAD_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const deleteModStudent = (id, accessToken) => (dispatch) => {
    return StudentService.deleteModStudent(id, accessToken).then(
      (response) => {
            dispatch({
          type: DELETE_MODSTUDENT_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: DELETE_MODSTUDENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getStudents = (coursename,accessToken) => (dispatch) => {
    return StudentService.getStudents(coursename,accessToken).then(
      (response) => {
            dispatch({
          type: GET_STUDENTS_SUCCESS,
          payload: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_STUDENTS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const getStudentMod = (id,accessToken) => (dispatch) => {
    return StudentService.getStudentMod(id,accessToken).then(
      (response) => {
            dispatch({
          type: GET_STUDENT_SUCCESS,
          payload: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_STUDENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateStudentMod = (id, fullname, slackname, accessToken) => (dispatch) => {
    return StudentService.updateStudentMod(id, fullname, slackname, accessToken).then(
      (response) => {
            dispatch({
          type: UPDATE_STUDENT_SUCCESS,
         });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_STUDENT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };