import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Table, InputGroup, Form } from "react-bootstrap";
import { CheckCircle, XCircle, Search } from "react-bootstrap-icons";
import "./listAttendance.css";


const ListAttendanceClass0 = (props) => {

  const { modStudents } = useSelector((state) => state.modStudents);

  const newDates = props?.dateList?.sort();

  const [searchStudent, setSearchStudent] = useState("");


  const handleFindStudent = (e) => {
    setSearchStudent(e.target.value);
  }

  // Filter Student
  const filterStudents = modStudents?.payload?.students?.filter((student) => {
    return ((student.slackname.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1) || (student.email.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1))
  })


  let studentsCounts = [];
  let studentCount = 0;
  if (newDates) {
    for (const dt of newDates) {
      let count = 0;
      for (const student of modStudents?.payload?.students) {
        if (student.courses[0]?.classes[0]?.attendencies?.dates.includes(dt)) {
          count++;
        }
      }
      studentsCounts.push(count);
    }
    studentCount = modStudents?.payload?.students.length;
  }
  // style={{ "writingMode": "tb-rl", "minWidth": "15px" }}
  return (
    <div>
      <div className='mt-4 mb-2'>
        <InputGroup>
          <InputGroup.Text id="basic-addon1"><Search /></InputGroup.Text>
          <InputGroup.Text>Email or Slack Name</InputGroup.Text>
          <Form.Control
            aria-label="searchstudent"
            name="searchstudent"
            value={searchStudent}
            onChange={handleFindStudent}
          />
        </InputGroup>
      </div>
      {(newDates?.length > 0 && modStudents?.payload?.students) ? (
        <div className='mt-3 freeze-table'>
          <Table bordered hover responsive size="sm">
            <thead style={{ "color": "#434242" }}>
              <tr>
                <th colSpan={3} className="col-id-total" style={{"backgroundColor":"#e9ecef"}}>Total Students : {studentCount}</th>
                
                {newDates?.map((item, i) => (
                  <th key={i} style={{ "writingMode": "tb-rl", "minWidth": "15px" }}>{new Date(item).toISOString().split('T')[0]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr style={{ "color": "#434242", "verticalAlign": "middle", "textAlign": "center" }}>
                {/* <th>N.</th> */}
                <th className="col-email" style={{"backgroundColor":"#e9ecef"}}>Email</th>
                <th className="col-slackname" style={{"backgroundColor":"#e9ecef"}}>Slack Name</th>
                <th className="col-rate" style={{"backgroundColor":"#e9ecef"}}>Rate</th>
                {studentsCounts?.map((item, index) => {
                  return (
                    <td key={index}><span><strong><span style={{ "color": "#38E54D", "fontSize": "1.05em" }}>{item}</span></strong><br></br><span style={{ "fontSize": "0.9em" }}>%{((item * 100) / studentCount).toFixed(1)}</span></span></td>
                  )
                })}
              </tr>
              {filterStudents.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td className="col-email" scope="row">{item.email}</td>
                    <td className="col-slackname" scope="row">{item.slackname}</td>
                    <td className="col-rate" scope="row"> %{newDates ? (Math.round(100 / ((newDates?.length) / (item?.courses[0]?.classes[0]?.attendencies?.dates.length)))) : 0} </td>
                    {newDates?.map((v, i) => (
                      <td key={i}>
                        {
                          item?.courses[0]?.classes[0]?.attendencies.dates.includes(v) ?
                            <CheckCircle color="#38E54D" size={20} /> : <XCircle color="#FC4F4F" size={20} />}</td>
                    ))}
                  </tr>

                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="mt-3 mb-3">
          <h3>Empty List...</h3>
        </div>

      )

      }
    </div>
  );
};

export default ListAttendanceClass0;


