import { combineReducers } from "redux";

import auth from "./auth";
import message from "./message";
import student from "./student.reducer";
import upload from "./fileUpload.reducer";
import students from "./students.reducer";
import accessCodes from "./accessCode.reducer";
import modStudents from "./modStudents.reducer";
import attendances from "./attendance.reducer";
import modAttendances from "./modAttendance.reducer";
import classes from "./class.reducer";
import courses from "./course.reducer";
import admincourses from "./adminCourse.reducer";
import allUsers from "./user.reducer";
import notifications from "./notification.reducer";

export default combineReducers({
  auth,
  message,
  student,
  upload,
  students,
  accessCodes,
  modStudents,
  attendances,
  modAttendances,
  classes,
  courses,
  admincourses,
  allUsers,
  notifications
});


