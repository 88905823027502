import {
    CREATE_ATTENDANCE_SUCCESS,
    CREATE_ATTENDANCE_FAIL,
    GET_MOD_ATTENDANCES_SUCCESS,
    GET_MOD_ATTENDANCES_FAIL,
    GET_MOD_ATTENDANCES_CLASS_SUCCESS,
    GET_MOD_ATTENDANCES_CLASS_FAIL,
    SET_MESSAGE,
  } from "./types";
  
  import AttendanceService from "../services/attendance.service";


 export const createAttendance = (email, code) => (dispatch) => {
    return AttendanceService.createAttendance(email, code).then(
      (response) => {
         dispatch({
          type: CREATE_ATTENDANCE_SUCCESS,
          payload: response.data.zoomlink,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_ATTENDANCE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  
  export const getAttendances = (accessCode) => (dispatch) => {
    return AttendanceService.getAttendances(accessCode).then(
      (response) => {
          dispatch({
          type: GET_MOD_ATTENDANCES_SUCCESS,
          payload: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_ATTENDANCES_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const getAttendancesForClass = (name, accessCode) => (dispatch) => {
    return AttendanceService.getAttendancesForClass(name, accessCode).then(
      (response) => {
          dispatch({
          type: GET_MOD_ATTENDANCES_CLASS_SUCCESS,
          payload: response.data
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_ATTENDANCES_CLASS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

