import React, { useState, useRef, useEffect} from "react";
import { useDispatch,useSelector} from "react-redux";
import { Col, Form } from "react-bootstrap";
import { createStudent } from "../actions/student.action";

import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const validNames = (value) => {
  if (value.length < 2 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 2 and 20 characters.
      </div>
    );
  }
};



const AddSingleStudent = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [fullName, setFullName] = useState("");
  const [slackName, setSlackName] = useState("");
  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);
 
  const user = useSelector((state) => state.auth.user);
  const {accessToken} = useSelector((state) => state.auth.user);
  
  const dispatch = useDispatch();

  const onChangeFullName = (e) => {
        const fullname = e.target.value;
        setFullName(fullname);
     };
    
   const onChangeSlackName = (e) => {
        const slackname = e.target.value;
        setSlackName(slackname);
      };
    
      const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
      };
    
    
  const handleFormSubmit = (e) => {
    e.preventDefault();

    setSuccessful(false);

    props.setIsStudents(false);

    form.current.validateAll();

   if (checkBtn.current.context._errors.length === 0) {
    
       dispatch(createStudent(fullName, slackName, email, accessToken))
        .then(() => {
          setSuccessful(true);
          props.setIsStudent(true);
         })
        .catch(() => {
          setSuccessful(false);
          props.setIsStudent(true);
        });
    }

 }

return (
    <div>
      <div style={{ maxWidth: "850px", margin: "0 auto" }}>
        <Col lg={6} style={{ margin: "0 auto" }}>

         <Form1 onSubmit={handleFormSubmit} ref={form}>
            <div>
              <div className="form-group">
                <label htmlFor="fullname">Student Full Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fullname"
                  value={fullName}
                  onChange={onChangeFullName}
                  validations={[required, validNames]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="slackname">Student Slack Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="slackname"
                  value={slackName}
                  onChange={onChangeSlackName}
                  validations={[required, validNames]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Student Email Address</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label>Student Course</label>
                <div className="fw-light text-capitalize">
                   <label>{user.coursename.split("-").join(" ")}</label>
                </div>
                
              </div>

              <div className="form-group d-grid gap-2 my-3">
                <button className="btn btn-primary">Save</button>
              </div>
            </div>
        
          {(props.message.message && props.isStudent) && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {props.message.message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form1>
      </Col>
      </div>
    </div>
  );
};

export default AddSingleStudent;

