import axios from "axios";
import { API_url } from '../config/config';


const API_URL = `${API_url}/api/`;


const createAttendance= async (email,code) => {

  return await axios.post(API_URL + "add-attendance",{email, code});

};



const getAttendances= async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-attendance",config);

};

const getAttendancesForClass= async (name, accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.post(API_URL + "get-attendance-forclass",{name},config);

};

  export default {
    createAttendance,
    getAttendances,
    getAttendancesForClass
  };
  