import {
    CREATE_ACCESSCODE_SUCCESS,
    CREATE_ACCESSCODE_FAIL,
    GET_ACCESSCODE_SUCCESS,
    GET_ACCESSCODE_FAIL,
    UPDATE_ACCESSCODE_SUCCESS,
    UPDATE_ACCESSCODE_FAIL,
    DELETE_ACCESSCODE_SUCCESS,
    DELETE_ACCESSCODE_FAIL,
    SET_MESSAGE,
  } from "./types";
  
  import AccessCodeService from "../services/accessCode.service";


 export const createAccessCode = (lessondate,zoomlink, _class, accessToken) => (dispatch) => {
    return AccessCodeService.createAccessCode(lessondate,zoomlink, _class, accessToken).then(
      (response) => {
         dispatch({
          type: CREATE_ACCESSCODE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_ACCESSCODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getAccessCode = (accessToken) => (dispatch) => {
    return AccessCodeService.getAccessCode(accessToken).then(
      (response) => {
         dispatch({
          type: GET_ACCESSCODE_SUCCESS,
          payload: response.data.codes,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_ACCESSCODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const updateAccessCode = (id, accessToken) => (dispatch) => {
    return AccessCodeService.updateAccessCode(id, accessToken).then(
      (response) => {
         dispatch({
          type: UPDATE_ACCESSCODE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_ACCESSCODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };


  export const deleteAccessCode = (id, accessToken) => (dispatch) => {
    return AccessCodeService.deleteAccessCode(id, accessToken).then(
      (response) => {
         dispatch({
          type: DELETE_ACCESSCODE_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: DELETE_ACCESSCODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };



 