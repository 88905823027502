import axios from "axios";
import { API_url } from '../config/config';


const API_URL = `${API_url}/api/`;

const getNotifications = async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-notifications",config);

};

const getUnReadNotifications = async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-unreadnotifications",config);

};

const sendEmail = async (id, accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + `send-email/${id}`,config);

};


const deleteNotification = async (id, accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + `delete-noti/${id}`,config);

};



  export default {
    getNotifications,
    getUnReadNotifications,
    sendEmail,
    deleteNotification
  };
  