import React, { useState, useRef,useEffect,CSSProperties} from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import PropagateLoader from "react-spinners/RingLoader";

import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { addAdminCourse } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const CreateCourseAccount = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  const [successful, setSuccessful] = useState(false);
  const [coursename, setCourseName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [message2, setMessage2] = useState("");
  const [spinner, setSpinner] = useState(true);

 
  useEffect(() => {
    if (props?.courses) {
      setSpinner(false);
    }
   }, [props?.courses]);

  const onChangeCourseName = (e) => {
    const coursename = e.target.value;
    setCourseName(coursename);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else {
      setPassword1(e.target.value);
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (password !== password1) {
      setMessage2("Passwords do not match!");
    } else if (checkBtn.current.context._errors.length === 0) {
      dispatch(addAdminCourse(coursename, email, password, props.user.accessToken))
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20px auto",
    borderColor: "red",
  };

  const emptyMessage = "No courses listed yet!";
  const courseForm = (
    <Form1 onSubmit={handleRegister} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="coursename">Soft Innovas Course Name</label>
              <Form.Select name="coursename" onChange={onChangeCourseName} required>
                <option className="fw-light"></option>
                {props?.courses?.map((item, index) => {
                  return (
                    <option key={index} value={item.name} className="text-capitalize">
                      {item.name.split("-").join(" ")}
                    </option>
                  );
                })}
              </Form.Select>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required, validEmail]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required, vpassword]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password1">Password Verification</label>
              <Input
                type="password"
                className="form-control"
                name="password1"
                value={password1}
                onChange={onChangePassword}
                validations={[required, vpassword]}
              />
            </div>

            <div className="form-group d-grid gap-2 my-3">
              <button className="btn btn-primary">Save</button>
            </div>
          </div>
          {(props?.message || message2) && (
            <>
              <div className="form-group">
                <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {props?.message} {message2}
                </div>
              </div>
            </>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form1>
  );

  return (
    <div className="col-md-12">
      <div className="card card-container">
      <PropagateLoader color={'#04a0c8'} cssOverride={override} loading={spinner}/>
        <img
          src="images/softinnovas600px.png"
          alt="profile-img"
          className="profile-img-card"
        />
        
          {spinner ? emptyMessage : courseForm}
        
      </div>
    </div>
  );
};

export default CreateCourseAccount;


