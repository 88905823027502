import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import { fileUpload, createStudents } from "../actions/student.action";

function AddMultipleStudent(props) {

	const [selectedFile, setSelectedFile] = useState();
	const [successful, setSuccessful] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [isUploaded, setIsUploaded] = useState(false);

	const { accessToken } = useSelector((state) => state.auth.user);

	const dispatch = useDispatch();

	const changeHandler = (event) => {
		props.setIsStudents(false);
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmission = () => {
		props.setIsStudent(false);

		dispatch(fileUpload(selectedFile, accessToken))
			.then(() => {
				setSuccessful(true);
			})
			.catch(() => {
				setSuccessful(false);
			});

		setIsUploaded(true);
		setIsSelected(false);
		props.setIsStudents(true);
	};


	const handlerSave = () => {
		props.setIsStudent(false);

		dispatch(createStudents(selectedFile.name, accessToken))
			.then(() => {
				setSuccessful(true);
			})
			.catch(() => {
				setSuccessful(false);
			});
		props.setIsStudents(true);
		setIsUploaded(false);
	}

	const handlerClear = () => {
        window.location.reload(false);
	}

	return (
		<div>
			<input type="file" name="file" style={{ color: "red" }} onChange={changeHandler} />
			{isSelected ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					{/* <p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p> */}
				</div>
			) : (
				<p>Select a file to show details. You can select only .xlsx files.</p>
			)}
			<div>
				{(props.message.message && props.isStudents) && (
					<div className="form-group">
						<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
							{props.message.message}
						</div>
					</div>
				)}
			</div>
			<div className="mt-3">
				<button
					type="button"
					className={isSelected ? "btn btn-primary" : "btn btn-primary disabled"}
					onClick={handleSubmission}>Upload File
				</button>
				<button
					type="button"
					className={props.upload.isUploded && isUploaded ? "btn btn-info ms-3" : "btn btn-info ms-3 disabled"}
					onClick={handlerSave}>Save Data
				</button>
				<button
					type="button"
					className="btn btn-secondary ms-3"
					onClick={handlerClear}>Clear
				</button>
			</div>

			{/* added new data */}
			{props.students.students[0]?.newData.length > 0 &&
				<div className='mt-3'>
					<div className='mb-3 mt-3'>
						<h5>
							Registered new students list...
						</h5>
						
					</div>

					<Table striped bordered hover responsive>
						<thead>
							<tr style={{ fontWeight: "bolder" }}>
								<th>Full Name</th>
								<th>Slack Name</th>
								<th>Email</th>
							</tr>
						</thead>
						<tbody>
							{props.students.students[0]?.newData.map((item, index) => {
								return (
									<tr key={index}>
										<td>{item.fullname}</td>
										<td> {item.slackname}</td>
										<td>{item.email}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			}
            {/* Added same data */}
			{props.students.students[0]?.same.length > 0 &&
				<div className='mt-3'>
					<div className='mb-3 mt-3'>
						<h5>
							Updated students list...
						</h5>
						<p><span className='text-danger'>*</span>These students already exist so also added to this course.</p>
					</div>

					<Table striped bordered hover responsive>
						<thead>
							<tr style={{ fontWeight: "bolder" }}>
								<th>Full Name</th>
								<th>Slack Name</th>
								<th>Email</th>
							</tr>
						</thead>
						<tbody>
							{props.students.students[0]?.same.map((item, index) => {
								return (
									<tr key={index}>
										<td>{item.fullname}</td>
										<td> {item.slackname}</td>
										<td>{item.email}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			}


		</div>

	)
}

export default AddMultipleStudent;

