import {
GET_MOD_COURSE_SUCCESS,
GET_MOD_COURSE_FAIL,
GET_ADM_COURSE_SUCCESS,
GET_ADM_COURSE_FAIL,
CREATE_COURSENAME_SUCCESS,
CREATE_COURSENAME_FAIL,
UPDATE_COURSENAME_SUCCESS,
UPDATE_COURSENAME_FAIL,
SET_MESSAGE,
  } from "./types";
  
  import CourseService from "../services/course.service";

// For moderator
 export const getCourses = (accessToken) => (dispatch) => {
    return CourseService.getCourses(accessToken).then(
      (response) => {
         dispatch({
          type: GET_MOD_COURSE_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_COURSE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  //for super-Admin
  export const getCoursess = (accessToken) => (dispatch) => {
    return CourseService.getCoursess(accessToken).then(
      (response) => {
         dispatch({
          type: GET_MOD_COURSE_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_COURSE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };



  
 export const getCoursesSa = (accessToken) => (dispatch) => {
  return CourseService.getCoursesSa(accessToken).then(
    (response) => {
       dispatch({
        type: GET_ADM_COURSE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ADM_COURSE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const createCourseName = (name, accessToken) => (dispatch) => {
  return CourseService.createCourseName(name, accessToken).then(
    (response) => {
       dispatch({
        type: CREATE_COURSENAME_SUCCESS,
       });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_COURSENAME_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateCourseNameStatus = (id,accessToken) => (dispatch) => {
  return CourseService.updateCourseNameStatus(id,accessToken).then(
    (response) => {
       dispatch({
        type: UPDATE_COURSENAME_SUCCESS,
       });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_COURSENAME_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

  