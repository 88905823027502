// export default function capitalize(str) {
//    `${str.charAt(0).toUpperCase()}${str.slice(1)}`; 
//    }

    


   export default function capitalize(str) {
      let arr = str.split(" ");
      return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()+" ";
      });
   }