import {
  GET_MOD_CLASS_SUCCESS,
  GET_MOD_CLASS_FAIL,
  CREATE_CLASSNAME_SUCCESS,
  CREATE_CLASSNAME_FAIL,
  UPDATE_CLASSNAME_SUCCESS,
  UPDATE_CLASSNAME_FAIL,
  SET_MESSAGE,
  } from "./types";
  
  import ClassService from "../services/class.service";

  export const createClassName = (classname, accessToken) => (dispatch) => {
    return ClassService.createClassName(classname, accessToken).then(
      (response) => {
         dispatch({
          type: CREATE_CLASSNAME_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_CLASSNAME_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const updateClassNameStatus = (id, accessToken) => (dispatch) => {
    return ClassService.updateClassNameStatus(id, accessToken).then(
      (response) => {
         dispatch({
          type: UPDATE_CLASSNAME_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_CLASSNAME_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };



 export const getClasses = (accessToken) => (dispatch) => {
    return ClassService.getClasses(accessToken).then(
      (response) => {
         dispatch({
          type: GET_MOD_CLASS_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_CLASS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const getClassesSa = (accessToken) => (dispatch) => {
    return ClassService.getClassesSa (accessToken).then(
      (response) => {
         dispatch({
          type: GET_MOD_CLASS_SUCCESS,
          payload: response.data,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_MOD_CLASS_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  