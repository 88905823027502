import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { getStudentMod } from "../actions/student.action";
import { updateStudentMod } from "../actions/student.action";


import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const EditStudentComp = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const dispatch = useDispatch();

    const [successful, setSuccessful] = useState(false);
    const [slackname, setSlackname] = useState("");
    const [fullname, setFullname] = useState("");
    const [isActive, setIsActive] = useState();

    let { message } = useSelector(state => state.message);
    const student = useSelector((state) => state.student.student.student);

    useEffect(() => {
        dispatch(getStudentMod(props.studentId, props.accessToken));
      }, [props.studentId]);

      useEffect(() => {
        setFullname(student?.fullname);
        setSlackname(student?.slackname);
        setIsActive(student?.isActive);
    }, [student])

    
    const onChangeFullName = (e) => {
        setFullname(e.target.value);
    };

    const onChangeSlackName = (e) => {
        setSlackname(e.target.value);
    };

    const handleChangeStatus = (e) => {
       
    }

    const handleFormSubmitUpdate = (e) => {
        setSuccessful(false);
        e.preventDefault();
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(updateStudentMod(props.studentId, fullname, slackname, props.accessToken))
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    }

    return (
        <div>
            <div style={{ maxWidth: "850px", margin: "0 auto" }}>
                <Col lg={6} style={{ margin: "0 auto" }}>
                    <Form1 onSubmit={handleFormSubmitUpdate} ref={form}>
                        <div>
                            <div className="form-group">
                                <label htmlFor="fullname">Student Full Name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="fullname"
                                    value={fullname}
                                    onChange={onChangeFullName}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="slackname">Student Slack Name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="slackname"
                                    value={slackname}
                                    onChange={onChangeSlackName}
                                    validations={[required]}
                                />
                            </div>
                            {/* <div key="radio" className="mb-3 mt-3">
                                <Form.Check
                                    inline
                                    name="studentStatus"
                                    type="switch"
                                    title="Student Status"
                                    checked={isActive}
                                    onChange ={handleChangeStatus}
                                /> 
                            </div>*/}
                            <div className="form-group d-grid gap-2 my-3">
                                <button className="btn btn-primary">Update</button>
                            </div>
                        </div>

                        {(message) && (
                            <div className="form-group">
                                <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form1>
                </Col>

            </div>
        </div>
    );
};

export default EditStudentComp;

