import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab} from "react-bootstrap";

import Capitalize from "../common/Capitalize"
import { getModStudents } from "../actions/student.action";
import {getAccessCode} from "../actions/accessCode.action";
import { getClasses } from "../actions/class.action";

import ListAttendanceClass0 from "../components/ListAttendanceClass0";

const BoardAttendances = () => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const classes = useSelector((state) => state.classes.payload);
	const accessCodes = useSelector((state) => state.accessCodes.payload);

	const [selectedTab, setSelectedTab] = useState("")
	const [selectedDate, setSelectedDate] = useState()

	useEffect(() => {
		dispatch(getModStudents(selectedTab, user.accessToken));
		dispatch(getAccessCode(user.accessToken));
		dispatch(getClasses(user.accessToken));
	}, []);

	useEffect(() => {
		dispatch(getModStudents(selectedTab, user.accessToken));
	}, [dispatch, selectedTab]);

	const getDateList = (lesson) => {
		return accessCodes?.filter((v,i) => v.class === lesson).map(item => item.lessondate);
	} 

	const handleTabSelect = (e) =>{
		setSelectedTab(e);
		setSelectedDate(getDateList(e));
	}

	return (
		<div
		className="card shadow p-3 mt-3 boarduser"
		style={{
		//   maxWidth: "1200px",
		  margin: "0 auto",
		}}
	  >
		<div>
		  <Tabs defaultActiveKey="" id="mod-panel" className=" mb-3" onSelect={handleTabSelect}>
			{classes?.map((v,i)=>(
			<Tab key={i} eventKey={v.name} title={Capitalize(v.name.split("-").join(" "))}>
				<ListAttendanceClass0 dateList={selectedDate}/>	
			</Tab>
			))}
		  </Tabs>
		</div>
		{!selectedTab && <h6>Select course to view attendances.</h6>}
	  </div>
	);
};

export default BoardAttendances;
