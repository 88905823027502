import axios from "axios";
import { API_url } from '../config/config'


const API_URL = `${API_url}/api/`;

const updateAdminPassword = async (id,password, newPassword,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.put(API_URL + `update-admin-password/${id}`,{password, newPassword },config);

};


const getAllUsers = async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.get(API_URL + `all-users`,config);

};

const getOneUser = async (id,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.get(API_URL + `get-user/${id}`,config);
};

const updateAccount = async (accountId,coursename, email,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.post(API_URL + `update-user`,{accountId,coursename, email},config);

};

const updateAccountPassword = async (id, newPassword,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.put(API_URL + `update-password/${id}`,{newPassword},config);

};




export default {
  updateAdminPassword,
  getAllUsers,
  getOneUser,
  updateAccount,
  updateAccountPassword
};