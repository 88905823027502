import {
  GET_MOD_ATTENDANCES_SUCCESS,
  GET_MOD_ATTENDANCES_FAIL,
  GET_MOD_ATTENDANCES_CLASS_SUCCESS,
  GET_MOD_ATTENDANCES_CLASS_FAIL
} from "../actions/types";


const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MOD_ATTENDANCES_SUCCESS:
      return {
        ...state,
        payload
      };
    case GET_MOD_ATTENDANCES_FAIL:
      return {
        ...state,
      };
    case GET_MOD_ATTENDANCES_CLASS_SUCCESS:
      return {
        ...state,
        payload
      };
    case GET_MOD_ATTENDANCES_CLASS_FAIL:
      return {
        ...state
      };
    default:
      return {
        ...state,
      }
  }
}
