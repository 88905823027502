import React, { useState,useRef} from "react";
import { useSelector, useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import { Col,Button } from "react-bootstrap";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import CheckButton from "react-validation/build/button";

import { createAttendance } from "../actions/attendance.action";



const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};


const AttendanceMod = () => {

const form = useRef();
const checkBtn = useRef();

const dispatch = useDispatch();

const { message } = useSelector(state => state.message);

const [email, setEmail] = useState("");
const [accessCode, setAccessCode] = useState("");
const [successful, setSuccessful] = useState(false);

const zoomlink = useSelector((state) => state.attendances.payload);

const onChangeEmail = (e) =>{
  setEmail(e.target.value);
}

const onChangeCode = (e) =>{
  setAccessCode(e.target.value);
}


const handleFormSubmit = (e) =>{
  e.preventDefault();

  setSuccessful(false);

  form.current.validateAll();

  if (checkBtn.current.context._errors.length === 0) {
    dispatch(createAttendance(email, accessCode))
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
   }
}

const zoomHandlerClick = () =>{
  // window.location.replace(zoomlink);
  window.open(zoomlink);
}

 return (
    <div className="card shadow p-3 mt-3 pb-0 pb-4 boarduser" style={{maxWidth: "800px", margin: "0 auto",}}>
      
         <div className='mb-0 mt-3'>
            <h4 className="text-center bg-primary text-white mt-3 pt-3 pb-3">
              <span>Daily Attendance</span>
            </h4>
            <div className='mb-0 mt-4'>
              <ul>
                <li>
                  Please enter the e-mail address you use to enter the course.
                </li>
                <li>
                  Enter the daily access code given to you.
                </li>
              </ul>  
            </div>
          </div>
      
        <Col lg={4} style={{ margin: "0 auto" }}>
        {!successful &&
          <Form onSubmit={handleFormSubmit} ref={form}>
                  <div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                      />
                    </div>
  
                    <div className="form-group">
                      <label htmlFor="code">Access Code</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="code"
                        value={accessCode}
                        onChange={onChangeCode}
                        validations={[required]}
                      />
                    </div>  
        
                      <div className="form-group d-grid gap-2 my-3">
                        <button className="btn btn-primary">Submit</button>
                      </div>
                  </div>
                
             <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          }
       </Col>
       {(message) && (
                <div className="form-group">
                  <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                    {message}
                  </div>
                </div>
              )}
       {successful && 
            <div className='mt-3'>
              <h5>
                You can access the course with this link.
              </h5>
                   
              <div className="alert alert-primary mt-3" role="alert">
                  <Link to={{ pathname:zoomlink}} target="_blank">{zoomlink}</Link>
              </div>
              
              <div className="d-grid gap-2 mt-3 mb-3">
                  <Button variant="danger" onClick={zoomHandlerClick}>Zoom Link</Button>
              </div>
              
            </div>
            
          }
      </div>
  );
};

export default AttendanceMod;
