import React from 'react';
import Modal from 'react-bootstrap/Modal';

import EditStudentComp from "../components/EditStudentComp";

function EditStudentModal(props) {

 return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShowEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Student
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <EditStudentComp {...props}/>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default EditStudentModal;

