import React, {useEffect,useState, useCallback } from "react";
import { useSelector,  useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LoginPage from "./screens/LoginPage";
import HomePage from "./screens/HomePage";
import ProfilePage from "./screens/ProfilePage";
import Header from "./components/Header";
import { login, logout } from "./actions/auth";
import { history } from "./helpers/history";
import EventBus from "./common/EventBus";


import AuthVerify from "./common/AuthVerify";

import AttendanceMod from "./screens/AttendanceMod";
import BoardModerator from "./screens/BoardModerator";
import BoardAttendances from "./screens/BoardAttendances";
import GenerateCode from "./screens/GenerateCode";
import ModeratorClasses from "./screens/ModeratorClasses";
import AddCourseName from "./screens/AddCourseName";
import AddClassName from "./screens/AddClassName";
import ModInstructions from "./screens/ModInstructions";
import BoardCourse from "./screens/BoardCourse";
import AllCourseForAdmin from "./screens/AllCourseForAdmin"
import BoardNotifications from "./screens/BoardNotifications"
import Footer from "./components/Footer";
import Page404 from "./pages/Page404";

import PrivateRoute from './helpers/PrivateRoute';

const App = () => {
  const dispatch = useDispatch();

  // const [showAdminBoard, setShowAdminBoard] = useState(false);
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [userBoard, setUserBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

   // const user = JSON.parse(localStorage.getItem("user"));

  let moderator = currentUser?.roles?.includes("ROLE_MODERATOR");
  let admin = currentUser?.roles?.includes("ROLE_ADMIN");
  let _user = currentUser?.roles?.includes("ROLE_USER");


  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      // setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      // setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      // setUserBoard(currentUser.roles.includes("ROLE_USER"));
    } else {
      // setShowModeratorBoard(false);
      // setShowAdminBoard(false);
      // setUserBoard(false);
      moderator = false;
      admin = false;
      _user = false;
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
    
  }, [currentUser, logOut]);

  return (
    <Router history={history}>
      <div>
        <Header/>
        <div className="container mt-3">
          <Switch>
            {moderator ? <PrivateRoute exact path={["/", "/rules"]}  component={ModInstructions} isAuth={moderator} /> : 
            admin ? <PrivateRoute exact path={["/", "/add-course"]} component={BoardCourse} isAuth={admin} /> :
            <Route exact path={["/", "/home"]} component={AttendanceMod}/>
            }
            <Route exact path="/login" component={LoginPage} />
            {/* <Route exact path="/register" component={RegisterPage} /> */}
            <Route exact path="/profile" component={ProfilePage}/>
            <Route exact path="/participant-entry" component={AttendanceMod}/>
            <PrivateRoute exact path="/generate" component={GenerateCode} isAuth={moderator} />
            <PrivateRoute exact path="/add-student" component={BoardModerator} isAuth={moderator} />
            <PrivateRoute exact path="/classes" component={ModeratorClasses} isAuth={moderator}/>
            <PrivateRoute exact path="/attendances" component={BoardAttendances} isAuth={moderator} />
            <PrivateRoute exact path="/notification" component={BoardNotifications} isAuth={moderator} />
            <PrivateRoute exact path="/get-courses" component={AllCourseForAdmin} isAuth={admin} />
            <PrivateRoute exact path="/add-coursename" component={AddCourseName} isAuth={admin} />
            <PrivateRoute exact path="/add-classname" component={AddClassName} isAuth={admin} />
            <Route path="*" component={Page404} />
          </Switch>
        </div>

        <AuthVerify logOut={logout} />
        {/* <Footer/> */}
      </div>
    </Router>
  );
};

export default App;
