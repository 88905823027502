import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Tab, Tabs } from "react-bootstrap";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { updateAccount, updateAccountPassword } from "../actions/user.action";
import { clearMessage } from "../actions/message";

import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};


const EditAccountComp = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const checkBtn2 = useRef();
    const checkBtn3 = useRef();

    const dispatch = useDispatch();

    const [coursename, setCoursename] = useState("");
    const [email, setEmail] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [message2, setMessage2] = useState("");
    const [selectedTab, setSelectedTab] = useState("");

    const courses = useSelector(state => state.courses.payload);
    let { message } = useSelector(state => state.message);

    useEffect(() => {
        if(message){
           dispatch(clearMessage()); 
        }
    }, [selectedTab])

    useEffect(() => {
        setCoursename(props.courseName);
        setEmail(props.email);
    }, [props.courseName, props.email])

    const onChangeCourseName = (e) => {
        setCoursename(e.target.value);
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onChangePassword = (e) => {
        if (e.target.name === "newpassword") {
            setNewPassword(e.target.value);
        } else {
            setConfirmPassword(e.target.value)
        }
    }

    const handleFormSubmitUpdate = (e) => {
        setMessage2("");
        setSuccessful(false);
        e.preventDefault();
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(updateAccount(props.accountId, coursename, email, props.accessToken))
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    }

    const handleFormSupmitPassUp = (e) => {
        setMessage2("");
        setSuccessful(false);
        e.preventDefault();

        form.current.validateAll();

        if (newPassword !== confirmPassword) {
            setMessage2("Passwords do not match!");
        } else if (checkBtn2.current.context._errors.length === 0) {
            dispatch(updateAccountPassword(props.accountId, newPassword, props.accessToken))
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    }

    const handleSubmitType = () => {

    }

    const handleTabSelected = (e) => {
        setSelectedTab(e);
    }

    return (
        <div>
            <div style={{ maxWidth: "850px", margin: "0 auto" }}>
                <Tabs defaultActiveKey="updateaccount" id="mod-panel" className=" mb-3" onSelect={handleTabSelected}>
                    <Tab eventKey="updateaccount" title="Update Account">
                        <Col lg={6} style={{ margin: "0 auto" }}>
                            <Form1 onSubmit={handleFormSubmitUpdate} ref={form}>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="coursename">Soft Innovas Course Name</label>
                                        <Form.Select name="coursename" onChange={onChangeCourseName} required>
                                            <option value={coursename}>{coursename}</option>
                                            {courses?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.name} className="text-capitalize">
                                                        {item.name.split("-").join(" ")}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={email}
                                            onChange={onChangeEmail}
                                            validations={[required, validEmail]}
                                        />
                                    </div>
                                    <div className="mt-3 mb-3">
                                        <span style={{ "color": "red" }}>
                                            <ExclamationTriangleFill className="action-icon me-2" size={25} color="red" />
                                            If students are added to this course, you should be careful. You will not be able to see previously added students under the course you have modified.
                                        </span>

                                    </div>
                                    <div className="form-group d-grid gap-2 my-3">
                                        <button className="btn btn-primary">Update</button>
                                    </div>
                                </div>

                                {(message) && (
                                    <div className="form-group">
                                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form1>
                        </Col>
                    </Tab>
                    <Tab eventKey="update-account-password" title="Update Account Pasword">
                        <Col lg={6} style={{ margin: "0 auto" }}>
                            <Form1 onSubmit={handleFormSupmitPassUp} ref={form}>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="newpassword">New Password</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="newpassword"
                                            value={newPassword}
                                            onChange={onChangePassword}
                                            validations={[required, vpassword]}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="confirmpassword">New Password Confirm</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="confirmpassword"
                                            value={confirmPassword}
                                            onChange={onChangePassword}
                                            validations={[required, vpassword]}
                                        />
                                    </div>

                                    <div className="form-group d-grid gap-2 my-3">
                                        <button className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                                {(message || message2) && (
                                    <div className="form-group">
                                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                            {message} {message2}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn2} />
                            </Form1>
                        </Col>
                    </Tab>
                    <Tab eventKey="change-account-type" title="Change Account Type">
                        <Col lg={6} style={{ margin: "0 auto" }}>
                            <Form onSubmit={handleSubmitType}>
                                <div>
                                    <div className="form-group d-grid gap-2 my-3">
                                        {/* <button className="btn btn-primary">Update</button> */}
                                    </div>
                                </div>
                                {(message || message2) && (
                                    <div className="form-group">
                                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                            {message} {message2}
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </Col>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default EditAccountComp;

