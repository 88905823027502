import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";


import Form1 from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Redirect } from 'react-router-dom';

import { updateAdminPassword } from "../actions/user.action";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};


const ProfilePage = () => {

  const form = useRef();
  const checkBtn = useRef();

  const dispatch = useDispatch();

  const { user: currentUser } = useSelector((state) => state.auth);
  const admin = currentUser?.roles?.includes("ROLE_ADMIN");
  const { message } = useSelector(state => state.message);

  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message2, setMessage2] = useState("");
  const [successful, setSuccessful] = useState(false);


  const onChangePassword = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "newpassword") {
      setNewPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value)
    }
  }

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (newPassword !== confirmPassword) {
      setMessage2("Passwords do not match!");
    } else if (checkBtn.current.context._errors.length === 0) {
      dispatch(updateAdminPassword(currentUser._id, password, newPassword, currentUser.accessToken))
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  }

  console.log({ password, newPassword, confirmPassword })

  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>Profile</strong> <br /><br />
        </h3>
      </header>
      <p className="text-capitalize">
        <strong>Course :</strong> {currentUser.coursename.split("-").join(" ")}
      </p>
      <p>
        <strong>Email :</strong> {currentUser.email}
      </p>
      <strong>Authorities :</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul>
      {admin && (
        <div className="col-md-6">
          <Form1 onSubmit={handleRegister} ref={form}>
            <div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newpassword">New Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="newpassword"
                  value={newPassword}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="confirmpassword">New Password Confirm</label>
                <Input
                  type="password"
                  className="form-control"
                  name="confirmpassword"
                  value={confirmPassword}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group d-grid gap-2 my-3">
                <button className="btn btn-primary">Save</button>
              </div>
            </div>
            {(message || message2) && (
              <div className="form-group">
                <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {message} {message2}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form1>
        </div>
      )}

    </div>
  );
};

export default ProfilePage;
