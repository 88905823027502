import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import CreateCourseAccount from "../components/CreateCourseAccount";
import CourseAccounts from "../components/CourseAccounts";
import { getAllUsers } from "../actions/user.action";
import {getCoursess } from "../actions/course.action";


const BoardCourse = () => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.allUsers.payload);
  const { message } = useSelector(state => state.message);
  const courses = useSelector(state => state.courses.payload);

  useEffect(() => {
    dispatch(getCoursess(user.accessToken));
   }, []);


  const handleTabSelect = (e) => {
    if (e === "courses") {
      dispatch(getAllUsers(user.accessToken));
    }
  }
  return (
    <div
      className="card shadow p-3 mt-3 boarduser"
      style={{
        maxWidth: "1024px",
        margin: "0 auto",
      }}
    >
      <div>
        <Tabs defaultActiveKey="addcourse" id="mod-panel" className=" mb-3" onSelect={handleTabSelect}>
          <Tab eventKey="addcourse" title="Create Course Account">
            <CreateCourseAccount user={user} message={message} courses={courses}/>
          </Tab>
          <Tab eventKey="courses" title="All Course Accounts">
            <CourseAccounts user={user} users={users} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default BoardCourse;