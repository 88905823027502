import React from "react";

import { Link } from "react-router-dom";


const ModInstructions = () => {

  return (
    <div className="container">
      <header className="jumbotron">
        <h3 className="mb-3 mt-3">Rules for Admins</h3>
      </header>
      <div className="rules">
      <ul>
          <li>
            The e-mail address you are logged into represents a course.
          </li>
          <li>
             First of all, you must upload the student list of this course to the system.
          </li>
          <li>
             You can download the sample excel file <Link to="/files/attendances.xlsx" target="_blank" download>Download</Link> here to upload multiple students.
          </li>
          <li>
            Student email addresses in the Excel file must be unique.
          </li>
          <li>
            Student Full Name, Slack Name and Student Email is required areas.
          </li>
          <li>
            After the students are defined in the system, the code for the attendance must be generated before the lesson for each day begins.
          </li>
          <li>
            While generating the code, it is mandatory to enter the date of the lesson, the name of the lesson and the zoom link of the lesson.
          </li>
          <li>
            Since attendance will be tracked according to the course date you entered, it must be entered correctly.
          </li>
          <li>
            Disable the generated code to finish attendance. No student can take attendance and enter the class anymore.
          </li>
          <li>
           Under the "Attendances" menu, you can track the attendance of the students according to the courses.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ModInstructions;
