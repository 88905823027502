import axios from "axios";
import { API_url } from '../config/config';
import { clearMessage } from "../actions/message";

const API_URL = `${API_url}/api/`;


const createAccessCode = async (lessondate, zoomlink, _class, accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.post(API_URL + "create-access-code",{lessondate, zoomlink, _class}, config);

};


const getAccessCode = async(accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return await axios.get(API_URL + "get-access-code", config);
};


const updateAccessCode = async(id, accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };
  
   const data = await axios.put(`${API_URL}update-access-code/${id}`,"",config);

  return data;
};

const deleteAccessCode = async(id, accessToken) => {
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };
  
   const data = await axios.delete(`${API_URL}delete-access-code/${id}`,config);

  return data;
};



  export default {
    createAccessCode,
    getAccessCode,
    updateAccessCode,
    deleteAccessCode
  };
  