import {
  GET_ALLUSER_SUCCESS,
  GET_ALLUSER_FAIL,
  GET_USER_SUCCESS,
  GET_USER_FAIL
} from "../actions/types";


const initialState = { account: [] };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALLUSER_SUCCESS:
      return {
        ...state,
        payload
      };
    case GET_ALLUSER_FAIL:
      return {
        ...state,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        account: payload
      };
    case GET_USER_FAIL:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      }
  }
}
