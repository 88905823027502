import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import EventBus from "../common/EventBus";

import { getUnReadNotifications } from "../actions/notification.action";


import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import { history } from "../helpers/history";


export default function Header() {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [userBoard, setUserBoard] = useState(false);
  const [expanded, setExpanded] = useState(false);

const dispatch = useDispatch();

  const { user: currentUser } = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.notifications);
 
  // const { user } = useSelector((state) => state.auth);
  // const moderator = user?.roles?.includes("ROLE_MODERATOR");
  // const admin = user?.roles?.includes("ROLE_ADMIN");

 

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
   }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setUserBoard(currentUser.roles.includes("ROLE_USER"));
      dispatch(getUnReadNotifications(currentUser.accessToken));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Navbar
      bg="light"
      className="nav-container navbar my-3"
      variant="light"
      expand="lg"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src="images/logo_banner.png"
            height="30"
            className="d-inline-block align-top"
            alt=""
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ zIndex: 1 }}
          className="text-center ps-3 "
        >
          <Container id="navItems" className="d-xl-inline-flex">
            <Nav className="me-auto">
              {showAdminBoard && (
                <>
                  <Nav.Item>
                    <LinkContainer to="/add-course">
                      <Nav.Link
                        onClick={() => setExpanded(expanded ? false : "expanded")}
                      >
                        Accounts
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <LinkContainer to="/get-courses">
                      <Nav.Link
                        onClick={() => setExpanded(expanded ? false : "expanded")}
                      >
                        Courses
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <NavDropdown
                    title="Settings"
                    id="basic-nav-dropdown"
                  >
                    <Nav.Item>
                      <LinkContainer to="/add-coursename">
                        <Nav.Link
                          onClick={() => setExpanded(expanded ? false : "expanded")}
                        >
                          Add Course Name
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                      <LinkContainer to="/add-classname">
                        <Nav.Link
                          onClick={() => setExpanded(expanded ? false : "expanded")}
                        >
                          Add Class Name
                        </Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                  </NavDropdown>


                </>
              )}
              {showModeratorBoard && (
                <>
                  <Nav.Item>
                    <LinkContainer to="/rules">
                      <Nav.Link
                        onClick={() => setExpanded(expanded ? false : "expanded")}
                      >
                        Instructions
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/generate"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Generate Code
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/add-student"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Add Student
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/classes"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Students
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="/attendances"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Attendances
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="position-relative">
                    <Nav.Link
                      href="/notification"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Notification
                     
                    </Nav.Link>
                    {notification?.unReadCount > 0 &&
                     <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {notification?.unReadCount}
                        {/* <span className="visually-hidden">0</span> */}
                      </span>
                    }
                    
                  </Nav.Item>
                </>

              )}

              {userBoard && (
                <Nav.Item>
                  <LinkContainer to="/user" className="">
                    <Nav.Link
                      className="text-center pe-2"
                      onClick={() =>
                        setExpanded(expanded ? false : "expanded")
                      }
                    >
                      Profile
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              )}
              {currentUser ? (
                <NavDropdown
                  title={
                    currentUser &&
                    currentUser.email &&
                    currentUser.email.split("@")[0]
                  }
                  id="basic-nav-dropdown"
                >
                  <Container className="" id="dropDownItems">
                    <LinkContainer to="/profile" className="">
                      <Nav.Link
                        className="text-center pe-2"
                        onClick={() =>
                          setExpanded(expanded ? false : "expanded")
                        }
                      >
                        Profile
                      </Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/" onClick={logOut} className="">
                      <Nav.Link
                        className="text-center pe-2"
                        onClick={() =>
                          setExpanded(expanded ? false : "expanded")
                        }
                      >
                        Log Out
                      </Nav.Link>
                    </LinkContainer>

                  </Container>
                </NavDropdown>
              ) : (
                <>
                  {(window.document.location.pathname !== "/participant-entry") &&

                    <>
                      {/* 
                      <Nav.Item>
                        <LinkContainer to="/login" className="">
                          <Nav.Link
                            className="text-center pe-2"
                          // onClick={() =>
                          //   setExpanded(expanded ? false : "expanded")
                          // }
                          >
                            Login
                          </Nav.Link>
                        </LinkContainer>
                      </Nav.Item>
                      
                      <Nav.Item>
                        <LinkContainer to="/register" className="">
                          <Nav.Link
                            className="text-center pe-2"
                           >
                            Sign Up
                          </Nav.Link>
                        </LinkContainer>
                      </Nav.Item>   */}
                    </>

                  }
                </>
              )}
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
