import axios from "axios";
import { API_url } from '../config/config';

const API_URL = `${API_url}/api/`;


const createCourseName = async (name,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.post(API_URL + "create-course",{name},config);

};

const getCourses= async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.get(API_URL + "get-course",config);

};

const getCoursess = async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.get(API_URL + "get-courses",config);

};

const getCoursesSa= async (accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

   return await axios.get(API_URL + "get-coursesa",config);

};

const updateCourseNameStatus= async (id,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };
 
   return axios.put(`${API_URL}update-course/${id}`,"",config);

};

  export default {
    getCourses,
    getCoursesSa,
    createCourseName,
    updateCourseNameStatus,
    getCoursess
  } 