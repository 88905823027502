import axios from "axios";
import { API_url } from '../config/config'

const API_URL = `${API_url}/api/`;


const createStudent = (fullname, slackname, email, accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.post(API_URL + "add-student", { fullname, slackname, email }, config);
};


const fileUpload = (data, accessToken) => {

  const config = {
    headers:
    {
      "content-type": "multipart/form-data",
      'x-access-token': accessToken
    }
  };

  const formData = new FormData();

  formData.append('file', data);

  return axios.post(API_URL + "upload", formData, config)
}

const createStudents = (filename, accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.post(API_URL + "add-students", { filename }, config);
};

//Get moderator students
const getModStudents = (classname, accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.post(API_URL + "all-students-mod", { classname }, config);
}


const fileDownloadServices = (accessToken) => {

  //Header to send with the request
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };
  return axios.get(API_URL + "download", config);
};

//For moderator (admin)
const deleteModStudent = (id,accessToken) => {
  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.put(`${API_URL}delete-student/${id}`,"",config);
}

//Get super-admin students
const getStudents = (coursename,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.get(`${API_URL}all-students/${coursename}`,config);
}

//Get one student for admin
const getStudentMod = (id,accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.get(`${API_URL}get-student/${id}`,config);
}

//Update one student for admin
const updateStudentMod = (id, fullname, slackname, accessToken) => {

  const config = {
    headers: {
      'x-access-token': accessToken,
    },
  };

  return axios.put(`${API_URL}update-student/${id}`,{fullname, slackname},config);
}



export default {
  createStudent,
  fileUpload,
  createStudents,
  getModStudents,
  fileDownloadServices,
  deleteModStudent,
  getStudents,
  getStudentMod,
  updateStudentMod
};
