import {
 CREATE_STUDENTS_SUCCESS,
 CREATE_STUDENTS_FAIL
  } from "../actions/types";
  
  
  const initialState ={
    students:[]
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_STUDENTS_SUCCESS:
        return {
          ...state,
          students: [...state.students, payload],
          isStudents:true,
        };
      case CREATE_STUDENTS_FAIL:
        return {
          ...state,
          isStudents:true,
         };
     default:
        return {
          ...state,
          isStudents:false,
        };
    }
  }
  

  //students: [...state.students, payload],