import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Button, InputGroup, Form } from "react-bootstrap";
import { Search, Send, SendCheck,TrashFill } from "react-bootstrap-icons";

import { getNotifications, sendEmail, getUnReadNotifications, deleteNotification } from "../actions/notification.action";
import Capitalize from "../common/Capitalize";

const ModeratorClasses = () => {
  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);

  const [successful, setSuccessful] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [editId, setEditId] = useState("");
  const [searchStudent, setSearchStudent] = useState("");
  const [showEdit, setShowEdit] = useState(false)

  const user = useSelector((state) => state.auth.user);
  const notification = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getNotifications(user.accessToken));
  }, []);

  useEffect(() => {
    if(successful){
      dispatch(getNotifications(user.accessToken));
      dispatch(getUnReadNotifications(user.accessToken));
    }
  }, [successful]);

  const handleClose = () => setShow(false);

  const handlerOpenModal = (id) => {
    setId(id);
    setShow(true);
  }

  const handlerOpenEditModal = (id) => {
    setEditId(id);
    setShowEdit(true);
  }

  const handleFindStudent = (e) => {
    setSearchStudent(e.target.value);
  }

  const handlerDeleteNotification = (id) =>{
    setSuccessful(false);
    dispatch(deleteNotification(id, user.accessToken))
     .then(() => {
       setSuccessful(true);
       window.location.reload();
     })
     .catch(() => {
       setSuccessful(false);
     });

  } 

  const handlerSendMailClick = (id) => {
    setSuccessful(false);
     dispatch(sendEmail(id, user.accessToken))
      .then(() => {
        setSuccessful(true);
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  }

  return (
    <div className="card shadow p-3 mt-3 boarduser" style={{ maxWidth: "1024px", margin: "0 auto" }}>
      <div className='mt-3'>
        <div className='mb-3'>
          <h5 className="fw-light text-capitalize">
            <strong>Course :</strong> {user.coursename.split("-").join(" ")}
          </h5>
          <h4 className="text-center bg-primary mt-4 pt-3 pb-3">
            <span>Notification List</span>
          </h4>
        </div>
        {/* <div className='mt-4 mb-2'>
          <InputGroup>
            <InputGroup.Text id="basic-addon1"><Search/></InputGroup.Text>
            <InputGroup.Text>Email or Slack Name</InputGroup.Text>
            <Form.Control 
              aria-label="searchstudent"
              name="searchstudent" 
              value={searchStudent}
              onChange={handleFindStudent}
              />
          </InputGroup>
        </div> */}

        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
      </div>
      {notification?.allNotification[0] ? (
        <div className='mt-3'>
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Full Name</th>
                <th>Slack Name</th>
                <th>Email</th>
                <th>Lesson</th>
                <th>Create Date</th>
                <th>Send Email</th>
              </tr>
            </thead>
            <tbody>
              {notification?.allNotification[0].map((item, index) => {
                var date = new Date(item.createdAt).toLocaleDateString();
                return (
                  <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{Capitalize(item.fullname)}</td>
                    <td>{item.slackname}</td>
                    <td>{item.studentEmail}</td>
                    <td>{item.className}</td>
                    <td>{date}</td>
                    <td>
                      <div style={{ "textAlign": "center" }}>
                        {item.readstatus === "unRead" ?
                          <Send className="mx-3 action-icon" size={20} color="#55a630" title="Send Email" onClick={() => handlerSendMailClick(item._id)} /> :
                          <SendCheck className="action-icon" size={20} color="#55a630" title="Email Sended" />
                        }
                         <TrashFill className="action-icon" size={20} color="#e71d36" title="Delete" onClick={() => handlerDeleteNotification(item._id)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="mt-3 mb-3">
          <h3>Empty List...</h3>
        </div>

      )

      }
    </div>
  );
};

export default ModeratorClasses;

