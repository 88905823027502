import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Button, InputGroup, Form } from "react-bootstrap";
import { PencilFill, TrashFill, Search } from "react-bootstrap-icons";

import { getModStudents, deleteModStudent } from "../actions/student.action";
import Capitalize from "../common/Capitalize";
import EditStudentModal from "../Modals/EditStudentModal";


const ModeratorClasses = () => {
  const dispatch = useDispatch();

  const { message } = useSelector((state) => state.message);

  const [successful, setSuccessful] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [editId, setEditId] = useState("");
  const [searchStudent, setSearchStudent] = useState("");
  const [showEdit, setShowEdit] = useState(false)

  const user = useSelector((state) => state.auth.user);
  const { modStudents } = useSelector((state) => state.modStudents);

  useEffect(() => {
    dispatch(getModStudents("", user.accessToken));
  }, [showEdit]);

  const handleClose = () => setShow(false);

  const handlerOpenModal = (id) => {
    setId(id);
    setShow(true);
  }

  const handlerOpenEditModal = (id) => {
    setEditId(id);
    setShowEdit(true);
  }

  const handleFindStudent = (e) => {
    setSearchStudent(e.target.value);
  }

  const handlerDeleteClick = () => {
    dispatch(deleteModStudent(id, user.accessToken))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
    setTimeout(() => {
      setShow(false);
      dispatch(getModStudents("", user.accessToken));
    }, 2000);

  }

  // Filter Student
  const filterStudents = modStudents?.payload?.students?.filter((student) => {
    return ((student.slackname.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1) || (student.email.toLowerCase().indexOf(searchStudent.toLowerCase()) !== -1))
  })
  
  return (
    <div className="card shadow p-3 mt-3 boarduser" style={{ maxWidth: "1024px", margin: "0 auto" }}>
      <div className='mt-3'>
        <div className='mb-3'>
          <h5 className="fw-light text-capitalize">
            <strong>Course :</strong> {user.coursename.split("-").join(" ")}
          </h5>
          <h4 className="text-center bg-primary mt-4 pt-3 pb-3">
            <span>Student List</span>
          </h4>
        </div>
        <div className='mt-4 mb-2'>
          <InputGroup>
            <InputGroup.Text id="basic-addon1"><Search/></InputGroup.Text>
            <InputGroup.Text>Email or Slack Name</InputGroup.Text>
            <Form.Control 
              aria-label="searchstudent"
              name="searchstudent" 
              value={searchStudent}
              onChange={handleFindStudent}
              />
          </InputGroup>
        </div>
      </div>
      {modStudents?.payload?.students ? (
        <div className='mt-3'>
          <Table striped bordered hover responsive>
            <thead>
              <tr style={{ fontWeight: "bolder" }}>
                <th>N.</th>
                <th>Full Name</th>
                <th>Slack Name</th>
                <th>Email</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filterStudents.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{Capitalize(item.fullname)}</td>
                    <td>{item.slackname}</td>
                    <td>{item.email}</td>
                    <td>
                      <div style={{ "textAlign": "center" }}>
                        <PencilFill className="mx-3 action-icon" size={25} color="#55a630" title="Edit" onClick={() => handlerOpenEditModal(item._id)} />
                        <TrashFill className="action-icon" size={25} color="#e71d36" title="Delete" onClick={() => handlerOpenModal(item._id)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <EditStudentModal show={showEdit} setShowEdit={setShowEdit} studentId={editId} accessToken={user.accessToken}/>
          
        </div>
      ) : (
        <div className="mt-3 mb-3">
          <h3>Empty List...</h3>
        </div>

      )

      }
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <p>When you delete the student, all the attendances belonging to it will also be deleted.</p>
            <p>You will not see this student in your course.</p>
          </div>
          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handlerDeleteClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModeratorClasses;

